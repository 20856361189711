import { DateTime, type DateTimeOptions } from 'luxon'

/**
 * Esta função composable `useDateUtilsAux` é responsável por manipular datas
 * com suporte a fusos horários e localização com base na linguagem e preferências do usuário.
 *
 * @param {string} date - A string de data a ser manipulada (no formato ISO).
 * @param {string | null} [timeZone] - Opcional. O fuso horário de destino para o qual a data será convertida.
 * Se não for fornecido, o padrão será UTC ou o fuso horário do usuário salvo no local storage.
 * @param {boolean} [changeZone=true] - Opcional. Determina se a data deve ser convertida
 * para o fuso horário do usuário. O padrão é true.
 *
 * @returns {DateTime} - Um objeto `DateTime` do Luxon com o fuso horário e localidade apropriados.
 */

export const useDateUtilsAux = (
  date: string,
  timeZone?: string | null,
  changeZone = true,
) => {
  const languageStore = useLanguageStore()

  const options: DateTimeOptions = {}
  options.zone = 'utc' // Padroniza pra que as zonas sejam consideradas no UTC

  if (languageStore.userLanguage) {
    options.locale = languageStore.userLanguage.split('-')[0]
  }

  let dateAux = DateTime.fromISO(date, options)

  if (timeZone) {
    dateAux.setZone(timeZone)
  } else {
    const user = JSON.parse(localStorage.getItem('user') as string)
    if (user?.timeZone && changeZone) dateAux = dateAux.setZone(user.timeZone) // options.zone = user.timeZone
  }

  return dateAux
}
