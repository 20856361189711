import { DateTime } from 'luxon'

// * Arquivo utilitário para formatação de datas (ver composable useDateUtilsAux)

// Formata a data informada padronizando a time zone, dando a possibilidade de escolher o formato
// Retorna um objeto do tipo String
export const dateFormatString = (
  date: string,
  timeZone?: string,
  format?: string,
) => {
  const dateAux = useDateUtilsAux(date, timeZone)

  if (format) return dateAux.toFormat(format)
  else return dateAux.toLocaleString(DateTime.DATE_SHORT)
}

// Formata a data informada padronizando a time zone
// Retorna um objeto do tipo Luxon.DateTime
export const dateTimeFormat = (date: string, timeZone?: string) => {
  const options: any = {}

  if (timeZone) options.zone = timeZone

  const dateAux = DateTime.fromISO(date, options)

  return dateAux
}

// Formata a data informada padronizando a time zone, dando a possibilidade de escolher o formato
// Retorna um objeto do tipo String
export const dateTimeFormatString = (
  date: string,
  timeZone?: string,
  format?: string,
  changeZone = true,
) => {
  const dateAux = useDateUtilsAux(date, timeZone, changeZone)

  if (format) return dateAux.toFormat(format)
  else return dateAux.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
}

// Formata a data com base no seguinte exemplo: Oct 14, 1983
// Retorna um objeto do tipo String
export const dateFormatStringMed = (date: string, timeZone?: string) => {
  const dateAux = useDateUtilsAux(date, timeZone)
  return dateAux.toLocaleString(DateTime.DATE_MED)
}

// Formata a data com base no seguinte exemplo: Octuber 14, 1983 10:20 PM
// Retorna um objeto do tipo String
export const dateFormatStringFull = (
  date: string,
  timeZone?: string,
  changeZone = true,
) => {
  const dateAux = useDateUtilsAux(date, timeZone, changeZone)
  return dateAux.toLocaleString(DateTime.DATETIME_MED)
}

// Retorna um objeto do tipo String
export const formatHour = (date: string) => {
  const dateAux = useDateUtilsAux(date)
  return dateAux.toLocaleString(DateTime.TIME_SIMPLE)
}

// Formata a data com base no seguinte exemplo: Octuber 2000
// Retorna um objeto do tipo String
export const dateFormatMountNameAndYear = (date: string, timeZone?: string) => {
  const dateAux = useDateUtilsAux(date, timeZone)
  return dateAux.toFormat('MMMM yyyy')
}

export const dateToUTC = (date: Date) => {
  const dateAux = DateTime.fromISO(date.toISOString())

  return dateAux.toUTC()
}

// Retorna quantos dias faltam até a data esperada
// Recomenda-se que utilize um data futura
export const daysToDate = (date: string, timeZone?: string) => {
  const end = useDateUtilsAux(date, timeZone)
  const start = useDateUtilsAux(new Date().toISOString())

  const days = end.diff(start, 'days').toObject().days as number
  const roundedDays = Math.ceil(days)

  return Math.max(roundedDays, 0)
}

export const getUserDateFormat = (userDateFormat?: string) => {
  const defaultFormat = 'dd/MM/yyyy'
  const regex = /(dd\/mm\/yyyy|mm\/dd\/yyyy|yyyy\/mm\/dd|yyyy\/dd\/mm)/

  if (!userDateFormat) return defaultFormat

  const userDateFormatWithoutTime = userDateFormat.split(' ')[0]
  const match = userDateFormatWithoutTime.match(regex) || ['']

  const regexMonth = /mm/g
  const dayFormat = match[0].replace(regexMonth, 'MM')

  return dayFormat
}

export const getDateFromISO = (date: string) => {
  return DateTime.fromISO(date, { zone: 'utc' }).toJSDate()
}

export const getTimeAgoTranslationByDate = (
  date: DateTime,
  $t: Function,
): string => {
  const now = DateTime.now()
  const diff = Math.abs(now.diff(date).as('days'))

  if (diff >= 365) {
    const years = Math.floor(diff / 365)
    const yearsLabel =
      years === 1 ? $t('general.dates.year') : $t('general.dates.year')

    return `${years} ${yearsLabel} ${$t('general.dates.go')}`
  } else if (diff >= 30) {
    const months = Math.floor(diff / 30)
    const monthsLabel = months === 1 ? 'month' : 'months'

    return `${months} ${monthsLabel} ${$t('general.dates.go')}`
  } else {
    const days = Math.floor(diff)
    const daysLabel = days === 1 ? 'day' : 'days'

    return days === 0
      ? $t('general.dates.today')
      : `${days} ${daysLabel} ${$t('general.dates.go')}`
  }
}

export const isDate = (value: string) => {
  return DateTime.fromISO(value).isValid
}
